.secondary-color {
  color: #F18000 !important;
}

.navy-blue-color {
  color: #1B65A6 !important;
}


body {
  font-family: "Tajawal", sans-serif !important;
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
  color: black !important;
}

#root>div.container.my-5>div>div.col-lg-4.col-md-12>div>div.d-flex.justify-content-center.mt-1.w-100.border-top.p-3>div>button:nth-child(3)>svg {
  border-radius: 70% !important;
  margin-right: 10px !important;
}

#root>div.container.my-5>div>div.col-lg-4.col-md-12>div>div.d-flex.justify-content-center.mt-1.w-100.border-top.p-3>div>button:nth-child(2)>svg {
  border-radius: 70% !important;
  margin-right: 10px !important;
}

#root>div.container.my-5>div>div.col-lg-4.col-md-12>div>div.d-flex.justify-content-center.mt-1.w-100.border-top.p-3>div>button:nth-child(1)>svg {
  border-radius: 70% !important;
  margin-right: 10px !important;
}

.text-primary {
  color: #294595 !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f8f9fa;
  box-shadow: inset 0 0 3px rgb(173, 172, 172);
}

::-webkit-scrollbar-thumb {
  background: #263997;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffba35;
}

html {
  scroll-padding-top: 125px;
}

.btn-primary {
  background-color: #184799 !important;

}

.css-xxees4 .MuiOutlinedInput-root {
  padding: 0px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root,
.css-1p5q5e5-MuiStack-root,
.css-1p5q5e5-MuiStack-root {
  background-color: #fff !important;
  border-radius: 6px !important;
  overflow: hidden !important;
  height: 6vh !important;
  margin-bottom: 5px !important;
}

.swiper-button-next {
  background-color: #FFCF44;
  color: black !important;
  border-radius: 50px;
  width: 44px !important;

}

.swiper-button-next:after {
  font-size: 15px !important;
  font-weight: bold !important;
  /* left: 0 !important; */
}

.swiper-button-prev {
  background-color: #FFCF44;
  color: black !important;
  border-radius: 50px;
  width: 44px !important;

}

.swiper-button-prev:after {
  font-size: 15px !important;
  font-weight: bold !important;
  /* left: 0 !important; */
}

@media screen and (max-width: 480px) {

  .swiper-button-prev,
  .swiper-button-next {
    background-color: #FFCF44;
    color: black !important;
    border-radius: 50px;
    width: 25px !important;
    height: 25px !important;
    top: 53% !important;

  }

  .swiper-button-prev {
    right: 0px !important;

  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 12px !important;
    font-weight: bold !important;
  }
}

.intro-details-fixed {
  position: fixed;
  z-index: 10;
  width: auto !important;
}

.cardDetailsContainer {
  display: flex !important;
  align-items: flex-end !important;
  height: 100% !important;
}