/* .lang-change {
  cursor: pointer;
  margin-inline: 1rem;
} */

/* .switch-lang-button {
  width: 25%;
  height: 25%;
  line-height: 40px;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: white;
  background-color: #184799;
  font-size: 1.25rem;
  margin-inline: 1rem;
  font-weight: bold;
} */

/* .switch-lang-button:hover {
  font-weight: bolder;
} */

.ar-lang {
  margin-bottom: 8.5px;
}

.world-icon {
  font-size: 10px;
  padding: 0px;
  color: #0BB7C1;
}